import React from "react";
import Layout from "../components/main/layout/Layout";
import Footer from "../components/main/layout/footer/Footer";
import Navbar from "../components/main/layout/header/Navbar";
import Privacy from "../components/main/privacy/static";
import PageHeader from "../components/main/team/PageHeader";

export default function PrivacyPolicy() {
  return (
    <Layout>
      <Navbar hasNavLinks={false} darkBg />
      <PageHeader HeaderTitle="Privacy Policy" Parent="Pages" PageTitle="Faq" />
      <Privacy Title space />
      <Footer />
    </Layout>
  );
}
