import React from "react";
import Contact from "../../components/main/contact/Contact";
import Layout from "../../components/main/layout/Layout";
import Footer from "../../components/main/layout/footer/Footer";
import Navbar from "../../components/main/layout/header/Navbar";
import Screenshots from "../../components/main/screenshot/Screenshots";
import HeroOne from "./HeroOne";

export default function HomeMain() {
  return (
    <Layout>
      <Navbar darkBg />
      <HeroOne />
      {/* <Promo /> */}
      {/* <Features />
      <FeaturesTwo /> */}
      {/* <Cta /> */}
      {/* <Price /> */}
      {/* <Accordion />
      <TestimonialTwo /> */}
      {/* <VideoPromo /> */}
      <Screenshots title={"AimChat"} />
      <Screenshots title={"Stopwatches"} />
      <Screenshots title={"Speed Math"} />
      {/* <Team /> */}
      {/* <LatestNewsOne /> */}
      <Contact bgColor />
      {/* <BrandCarousel /> */}
      {/* <Subsribe /> */}
      <Footer space />
    </Layout>
  );
}
